import React, { Fragment } from "react";
import { Typography, Stack } from "@mui/material";
import Head from "next/head";
import Image from 'next/image'
import LogoImage from '../public/images/logo.png';
import Link from '@mui/material/Link';


const mainContainer = {
  height: '100vh',
  alignItems: 'center',
  '& h6': {
    fontSize: 16,
    fontWeight: 400,
  },
}

export default function MyApp(props) {

  return (
    <Fragment>
      <Head>
        <title>KN Overseas Tourism</title>
      </Head>
      <Stack display="flex" justifyContent="center" flexDirection="column" sx={mainContainer} spacing={2}>
        <Stack display="flex" justifyContent="space-between" flexDirection="row" sx={{ width: '50%', marginBottom:"40px"}}>
          <div className="d-flex align-items-center flex-row">
            <h6>Email:  <Link href="mailto:info@knoverseas.in">info@knoverseas.in</Link></h6>
          </div>
          <div className="d-flex align-items-center flex-row flex-end">
            <h6>Phone: <Link href="tel:918886545559">+91 88865 45559</Link><br/><span style={{marginLeft:59}}><Link href="tel:919030425837">+91 90304 25837</Link></span></h6>
          </div>
        </Stack>
        <Image src={LogoImage} alt="logo" width={270} height={125} />
        <Typography variant="h6" className="mb-2 mt-4">We are working on something awesome.</Typography>
        <Typography variant="h5" className="mb-5">Stay tuned!</Typography>
      </Stack>
    </Fragment>
  );
}
